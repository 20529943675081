export * from './dates'
export * from './routing'
export * from './analytics'
export * from './validation'
export * from './branded-fares'
export { default as NotificationDispatcher } from './NotificationDispatcher'

export const where = (key) => (value) => (obj) => obj[key] === value
export const whereNot = (key) => (value) => (obj) => obj[key] !== value

export const whereId = where('id')
export const whereName = where('name')
export const whereType = where('type')
export const whereUuid = where('uuid')
export const whereCode = where('code')
export const whereValue = where('value')
export const whereNotId = whereNot('id')
export const whereGender = where('gender')
export const whereCountry = where('country')
export const whereLanguage = where('language')
export const whereRoomIndex = where('roomIndex')
export const whereProductId = where('productId')
export const whereReturnDate = where('returnDate')
export const whereFullLocale = where('fullLocale')
export const whereSegmentType = where('segmentType')
export const whereArrivalCode = where('arrivalCode')
export const whereDirectFlight = where('directFlight')
export const whereDepartureCode = where('departureCode')
export const whereOriginalProductId = where('originalProductId')

export const delay = (time, value) =>
    new Promise((resolve) => {
        setTimeout(resolve, time, value)
    })

export const omitEmptyValues = (obj) =>
    Object.keys(obj).reduce((acc, key) => {
        const newAcc = { ...acc }
        const entry = obj[key]
        if (entry !== null && typeof entry !== 'undefined' && entry !== '') {
            newAcc[key] = obj[key]
        }
        return newAcc
    }, {})

export const variant = (prop, variants, otherwise) => {
    if (variants[prop]) {
        return variants[prop]
    }
    return otherwise
}

export const combineRefs =
    (refs = []) =>
    (el) => {
        refs.forEach((ref) => {
            if (typeof ref === 'function') {
                ref(el)
            } else {
                // eslint-disable-next-line no-param-reassign
                ref.current = el
            }
        })
    }

export function unique(v, i, a) {
    return a.indexOf(v) === i
}
