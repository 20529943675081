import {
    format,
    addDays,
    isAfter,
    isToday,
    isBefore,
    isTomorrow,
} from 'date-fns'

const millisecondsToParts = (milliseconds) => {
    /* eslint-disable no-mixed-operators */
    const totalSeconds = milliseconds / 1000
    const hours = (totalSeconds - (totalSeconds % 3600)) / 3600
    const minuteSeconds = Math.round(totalSeconds - hours * 3600)
    const minutes = (minuteSeconds - (minuteSeconds % 60)) / 60
    const seconds = Math.round(minuteSeconds - minutes * 60)
    return { hours, minutes, seconds }
}

export const getAgeFromDate = (date, reference = new Date()) => {
    const m = reference.getMonth() - date.getMonth()
    let age = reference.getFullYear() - date.getFullYear()
    if (m < 0 || (m === 0 && reference.getDate() < date.getDate())) {
        age -= 1
    }
    return age
}

export const millisecondsToFlightDurationString = (milliseconds) => {
    const { hours, minutes } = millisecondsToParts(milliseconds)
    return `${hours}h ${minutes}m`
}

export const fareTimeStringToMilliseconds = (fareTimeString) => {
    const withHours = fareTimeString.padStart(8, '00:')
    const withDays = withHours.padStart(11, '00.')
    const [days, rest] = withDays.split('.')
    const [hours, minutes, seconds] = rest.split(':')
    return days * 86400000 + hours * 3600000 + minutes * 60000 + seconds * 1000
}

export const getDaysBetween = (startDate, endDate) => {
    const difference =
        new Date(endDate).getTime() - new Date(startDate).getTime()
    return Math.round(difference / 1000 / 60 / 60 / 24)
}

export const SbToValidDate = (date) => new Date(date.replace(' ', 'T'))

export const getDayIsDisabledCondition = (day) => {
    const now = new Date()
    const deadline = new Date()
    deadline.setHours(17, 0, 0, 0)
    const isAfterDeadline = isTomorrow(day) && isAfter(now, deadline)

    const isBeforeToday = isBefore(day, now)

    const limit = addDays(now, 340)
    const isAfterLimit = isAfter(day, limit)

    return isBeforeToday || isToday(day) || isAfterDeadline || isAfterLimit
}

const getMultiFlightSegmentBeforeDate = (index, departureDate, segments) => {
    if (index === 0 && departureDate !== null) {
        return addDays(departureDate, 1)
    }
    if (index > 0) {
        const prevSegment = segments[index - 1]
        if (prevSegment.departureDate !== null) {
            return addDays(prevSegment.departureDate, 1)
        }
    }
    return addDays(new Date(), 1)
}

export const getMultiSelectDayIsDisabledCondition = (
    day,
    index,
    departureDate,
    extraSegments
) =>
    isBefore(
        day,
        getMultiFlightSegmentBeforeDate(index, departureDate, extraSegments)
    ) || isAfter(day, addDays(new Date(), 340))

export const getPreviousSegmentDepartureDate = (
    index,
    departureDate,
    segments
) => {
    if (index >= 1) {
        return segments[index - 1].departureDate
    }
    return departureDate
}

export function dateToTbDateString(date) {
    return date ? format(date, 'yyyy-MM-dd') : ''
}

/** --- formatTimeString() ------------------------------------------------------------- */
/** -i- Formats string in format "HH:MM:SS" to "HHh:MMm".
 * For example: "01:30:00" -> "1h:30m".
 */
export function formatTimeString(timeString) {
    const [hours, minutes] = timeString.split(':')
    const formattedHours = hours !== '00' ? `${parseInt(hours, 10)}h` : ''
    const formattedMinutes = minutes !== '00' ? `${parseInt(minutes, 10)}m` : ''

    return `${formattedHours}${
        formattedHours && formattedMinutes ? '' : ''
    }${formattedMinutes}`
}
